<template>
  <div :class="$style.detail">
    <div :class="$style.header">
      <div :class="$style.avatar">
        <img :src="data.avatar" />
        <div :class="$style.name">
          <span :class="$style.name">{{ data.nickname }}</span>
          <span :class="$style.state">{{ data.status | stateName }}</span>
          <p :class="$style.phone">{{ data.telephone }}</p>
        </div>
      </div>

      <div :class="$style.btn">
        <!-- <BjButton type="primary">
          联系用户
          <i class="ri-arrow-down-s-line right" />
        </BjButton> -->
        <!-- <BjButton class="btn-default"> 编辑资料 </BjButton> -->
        <a-dropdown placement="bottomRight">
          <BjButton type="primary">
            黑名单
            <i class="ri-arrow-down-s-line right" />
          </BjButton>
          <a-menu slot="overlay" class="list-drop">
            <a-menu-item>
              <bj-link>
                禁止评论
                <a-checkbox
                  class="pull-right"
                  :checked="data.status === 2 || data.status === 3"
                  @change="onStatus(data, 2)"
                />
              </bj-link>
            </a-menu-item>
            <a-menu-item>
              <bj-link>
                禁止访问
                <a-checkbox class="pull-right" :checked="data.status === 3" @change="onStatus(data, 3)" />
              </bj-link>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>

      <div :class="$style.lineBox">
        <div :class="$style.line" />
        <div :class="$style.drop" @click="onShow()">
          <i v-if="show" class="ri-arrow-up-s-line" />
          <i v-else class="ri-arrow-down-s-line" />
        </div>
        <div :class="$style.name" @click="onShow()">{{ show ? '折叠' : '展开' }}</div>
      </div>

      <div :class="$style.content">
        <p :title="data.id">用户ID: {{ data.id }}</p>
        <p :title="data.telephone">手机号码: {{ data.telephone }}</p>
        <p :title="data.real_name">真实姓名: {{ data.real_name }}</p>
        <p :title="data.gender">用户性别: {{ data.gender | sex }}</p>
        <p v-if="show" :title="data.birthday">用户生日: {{ data.birthday }}</p>
        <p v-if="show" :title="data.country + data.province + data.city">
          所在地区: {{ data.country + data.province + data.city }}
        </p>
        <p v-if="show" :title="data.source_name">首次来源: {{ data.source_name }}</p>
        <p v-if="show" :title="data.created_at">加入时间: {{ data.created_at }}</p>
        <p v-if="show" :title="data.updated_at">最近活跃时间: {{ data.updated_at }}</p>
      </div>

      <div :class="$style.tag">
        <p>
          用户标签：{{ tag }}
          <a-popover :trigger="['click']">
            <template slot="content">
              <div :class="$style.tags">
                <BjSelect
                  v-model="data.tag_ids"
                  mode="multiple"
                  :max-tag-count="1"
                  :max-tag-text-length="3"
                  key-field="id"
                  placeholder="请选择用户标签"
                  value-field="id"
                  label-field="name"
                  show-all
                  :options="tagArr"
                />
                <BjButton type="primary" @click="onEdit()">确定</BjButton>
              </div>
            </template>
            <bj-link class="ml-20" type="primary"> 贴标签 </bj-link>
          </a-popover>
        </p>
      </div>

      <div :class="$style.lines" />
      <div :class="$style.info">
        <a-popover v-for="item in platforms" :key="item.id" title="用户信息">
          <template slot="content">
            <div :class="$style.popover">
              <div :class="$style.name">
                <img :src="item.avatar" />
                <span>{{ item.nickname }}</span>
              </div>
              <p :title="item.open_id">OPENID：{{ item.open_id }}</p>
              <p>用户性别：{{ item.gender | sex }}</p>
              <p :title="item.area">所在地区：{{ item.area }}</p>
              <p :title="item.created_at">加入时间：{{ item.created_at }}</p>
            </div>
          </template>
          <img :class="$style.icon" :src="item.source_icon" />
        </a-popover>
      </div>
    </div>
  </div>
</template>

<script>
import { userListService } from '@/service'

const service = new userListService()

export default {
  name: 'Detail',
  filters: {
    stateName(val) {
      switch (val) {
        case 1:
          return '正常'
        case 2:
          return '功能受限'
        case 3:
          return '禁用'
      }
    },
    sex(val) {
      switch (val) {
        case 0:
          return '未知'
        case 1:
          return '男'
        case 2:
          return '女'
      }
    },
  },
  data() {
    return {
      data: {},
      tagArr: [],
      show: true,
      platforms: [],
    }
  },
  computed: {
    tag() {
      return this.data.tags ? this.data.tags.map(item => item.name).join('、') : ''
    },
  },
  created() {
    this.getDetail()
    this.getTag()
  },
  methods: {
    async getTag() {
      const { data } = await service.getTagList({
        no_page: 1,
      })
      this.tagArr = data
    },
    async getDetail() {
      try {
        const { data } = await service.getDetail({
          user_id: this.$route.params.id,
        })
        this.data = data.detail
        this.platforms = data.platforms
      } catch (e) {}
    },
    async onEdit() {
      try {
        await service.saveTag({
          user_ids: [this.data.id],
          tag_ids: this.data.tag_ids,
        })
        this.$message.success('修改成功')
        this.getDetail()
      } catch (e) {}
    },
    onShow() {
      this.show = !this.show
    },
    // 处理状态
    async onStatus(item, _state) {
      try {
        let state = 1
        // 处理取消情况 貌似没有规律 只能这么写
        if (item.status === 1) {
          state = _state
        } else if (item.status === 2) {
          if (_state === 2) {
            state = 1
          }
          if (_state === 3) {
            state = 3
          }
        } else if (item.status === 3) {
          if (_state === 2) {
            state = 1
          }
          if (_state === 3) {
            state = 2
          }
        }
        await service.saveStatus({
          user_ids: [item.id],
          status: state,
        })
        this.$message.success('修改成功')
        this.getDetail()
      } catch (e) {}
    },
  },
}
</script>

<style lang="less" module>
.detail {
  .header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px;
    background-color: #fff;

    .avatar {
      display: flex;

      img {
        width: 60px;
        height: 60px;
        margin-right: 14px;
        border-radius: 100%;
        object-fit: cover;
      }

      .name {
        padding-top: 4px;
      }

      .state {
        height: 23px;
        margin-left: 10px;
        padding: 3px;
        color: #434343;
        font-size: 12px;
        line-height: 23px;
        background-color: #f0f0f0;
        border-radius: 4px;
      }

      .phone {
        margin-top: 6px;
        margin-bottom: 0;
        color: #5c5c5c;
        font-size: 12px;
      }
    }

    .btn {
      width: 300px;
      text-align: right;
    }
  }

  .line-box {
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: 74px;

    .line {
      flex: 1;
      height: 1px;
      margin-right: 22px;
      border: 1px solid #eee;
    }

    .drop {
      width: 20px;
      height: 20px;
      color: #000;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      background-color: #eee;
      border-radius: 100%;
      cursor: pointer;
    }

    .name {
      padding-left: 10px;
      color: @primary-color;
      font-size: 13px;
      cursor: pointer;
    }
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 5px;
    padding-left: 74px;

    p {
      width: 25%;
      margin-bottom: 20px;
      padding-right: 10px;
      overflow: hidden;
      color: #5c5c5c;
      font-size: 14px;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .tag {
    padding-left: 74px;
    color: #5c5c5c;
    font-size: 14px;

    p {
      margin-bottom: 0;
    }
  }

  .lines {
    width: 100%;
    height: 1px;
    margin: 14px 0 14px 74px;
    border: 1px solid #eee;
  }

  .info {
    padding-left: 74px;

    .icon {
      width: 40px;
      height: 40px;
      margin-right: 10px;
      border-radius: 100%;
      object-fit: cover;
    }
  }
}

.tags {
  display: flex;
  align-items: center;
  width: 300px;

  :global {
    .ant-form-item {
      width: 190px;
      margin-right: 10px;
      margin-bottom: 0;
    }
  }
}

.popover {
  .name {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    color: #000;
    font-size: 14px;

    img {
      width: 40px;
      height: 40px;
      margin-right: 10px;
      border-radius: 100%;
      object-fit: cover;
    }
  }

  p {
    margin-bottom: 10px;
    overflow: hidden;
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>
